import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/owenb/Documents/ems/ems-website/src/components/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Response Time Track App`}</h1>
    <h3>{`Description`}</h3>
    <p>{`The Response Time Track App's goal is to allow paramedics to focus more on the rescue and less time on keeping track of reports.
It keeps tracks of the times of receiving calls, going on enroute, on scene, transport, and destination.
This data is saved for any desired use of the data.`}</p>
    <h3>{`Use`}</h3>
    <p>{`The app will keep track of each rescue, specifically tracking the time of call, enroute, scene, transport, and destination.
Each change in action of the rescue will only need a push of a button.
At the end of the trip, the mileage can be recorded for reporting.
These rescue records is saved on the phone, which can be saved as csv file, written down, or sent to a server.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      